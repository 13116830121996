<template>
  <div class="loginContainer preload" @contextmenu.self.prevent="show" @mousemove="mousemove">
    <div class="bg-img">
      <img src="@/assets/57086640_p0.jpg" alt="">
    </div>
    <div v-show="showImg" class="bg_div">
      <div class="my_div1" id="hideImg" ref="hideImg" @contextmenu.self.prevent="show">

      </div>
    </div>
    <div class="contentdiv">
      <img class="my_img" src="~@/assets/baka.jpg" alt="" width="120" height="120" />
      <div class="formdiv">
        <el-form :model="user" :rules="rules" ref="user" label-width="80px">
          <el-form-item label="用户名" prop="name">
            <el-input
              v-model="user.name"
              style="width: 270px"
              placeholder="请输入用户名"
              prefix-icon="iconfont icon-suoding"
              @keyup.native="commitKeyDown"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="user.password"
              style="width: 270px"
              placeholder="请输入密码"
              show-password
              prefix-icon="iconfont icon-grade"
              @keyup.native="commitKeyDown"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="12" class="btncol">
            <el-button type="primary" @click="subClick('user')"
              >立即登入</el-button
            >
            <el-button type="primary" @click="reInput">重置</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <footer class="footer"><a target="_blank" href="http://beian.miit.gov.cn">湘ICP备2021006182号-1</a></footer>
    <audio ref="bgAdudio" src="@/assets/audio/Flandre-音频1.mp3" autoplay></audio>
    <!-- <audio ref="bgAdudio" src="https://www.okloli.com/wp-content/themes/mygalgame/ui/audio/oni.mp3?ver=0.0.2" autoplay></audio> -->
  </div>
</template>

<script>
import {validatePass} from "@/utils/formrules";
import { Login } from "@/api/users";
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      showImg:false,
      user: {
        name: "",
        password: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 5,
            max: 15,
            message: "长度在 5 到 15 个字符",
            trigger: "blur",
          },
        ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'blur' },
        //   { min: 3, max: 5, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        // ]
        password: [
          { required: true, validator: validatePass.chkPwd, trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  mounted() {
    this.bgImgOnload();
  },
  methods: {
    bgImgOnload() {
      let imgParentDom = document.querySelector(".bg-img");
      let imgDom = document.querySelector(".bg-img img");
      imgDom.onload = () => {
        console.log('图片加载完成')
        imgParentDom.classList.add('bg-img-loaded');
      }
    },
    show(e) {
      // console.log('测试1：',e);
      // console.log('showImg',this.showImg);
      this.$set(this,'showImg',!this.showImg);
      let x = e.clientX;
      let y = e.clientY;
      // console.log('this.$refs.hideImg',x,y);
      // console.log('this.$refs.hideImg',dom.clientWidth,dom.clientWidth)
      this.$nextTick(() => {
        let dom = this.$refs.hideImg;
        if(!this.showImg) {
          dom.style.opacity = 0;
          this.$refs.bgAdudio.pause();
          return;
        }
        this.$refs.bgAdudio.load();
        this.$refs.bgAdudio.play();
        let width = dom.clientWidth / 2;
        let height = dom.clientWidth / 2;
        dom.style.cssText = 
        `
        opacity: 1;
        left:${x}px;
        top:${y}px;
        background-position:calc(-${x}px + ${width}px) calc(-${y}px + ${height}px);
        `;
      })
    },
    mousemove(e) {
      // console.log('e:',e);
      if(!this.showImg) return;
      let dom = this.$refs.hideImg;
      let x = e.clientX;
      let y = e.clientY;
      // console.log('测试坐标:',x,y);
      // console.log(document.querySelector('#hideImg').style);
      // console.log(this.$refs.hideImg.$el);
      // console.log('this.$refs.hideImg',dom.clientWidth,dom.clientWidth);
      let width = dom.clientWidth / 2;
      let height = dom.clientWidth / 2;
      dom.style.cssText = 
      `
      opacity:1;
      left:${x}px;
      top:${y}px;
      background-position:calc(-${x}px + ${width}px) calc(-${y}px + ${height}px);
      `;
    },
    commitKeyDown(e) {
      if(e.code == 'Enter') this.subClick('user');
      return
    },
    reInput() {
      this.$refs["user"].resetFields();
    },
    subClick(userStr) {
      this.$refs[userStr].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          let rs = await Login(this.user.name, this.user.password);
          // let rs = await axios.post("http://localhost:3000/users",this.user);
          console.log(rs);
          if (rs.code === 500) {
            this.$message.error("用户名或密码错误");
            return false;
          }
          sessionStorage.setItem("jwttoken", rs.data.token);
          console.log(sessionStorage.getItem("jwttoken"));
          this.$message.success("登录成功");
          // this.$store.commit('modifyUsers',rs[0])
          this.$router.push("/home");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
// body {
//   background-color: #ccc;
// }
.preload {
  &::before {
    content: "";
    background-image: url(../assets/80950741_p2.png) ;
  }
}
.my_div1 {
  width: 200px;
  // width: 100vw;
  height: 200px;
  // height: 100vh;
  border-radius: 100%;
  opacity: 0;
  transition: opacity 1s;
  box-shadow: 0 0 0 4px rgba(255,255, 255,0.7);
  z-index: 4;
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  transform: translate(-50%,-50%);
  // background-image: url(../assets/57086640_p0.jpg) ;
  // background-image: url(../assets/315810.jpg) ;
  background-image: url(../assets/80950741_p2.png) ;
  // background-size: 100vw 100vh;
  // background-size: cover;
}
.bg_div {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  background-color: rgba(0,0,0,0.5);
}
.loginContainer {
  // background-color: #ccc;
//   background-image: url(../assets/57086640_p0.jpg);
//   background-size: cover;
  width: 100%;
  height: 100vh;
  min-height: 550px;
//   z-index: -1;
  // background-color: #d6d3d3;
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  // backdrop-filter: blur(100px);
//   position: fixed;
//   &::after {
//     content: '';
//     width: 100vw;
//     height: 100vh;
//     position: absolute;
//     left: 0;
//     top: 0;
//     z-index: -1;
//     // filter: blur(3px);
//     backdrop-filter: blur(3px);
//   }

  .bg-img {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url("data:image/jpeg;base64,/9j/2wBDAAgICAgJCAkKCgkNDgwODRMREBARExwUFhQWFBwrGx8bGx8bKyYuJSMlLiZENS8vNUROQj5CTl9VVV93cXecnNH/2wBDAQgICAgJCAkKCgkNDgwODRMREBARExwUFhQWFBwrGx8bGx8bKyYuJSMlLiZENS8vNUROQj5CTl9VVV93cXecnNH/wAARCAAkAEADASIAAhEBAxEB/8QAGwAAAgMBAQEAAAAAAAAAAAAABQYABAcCAwH/xAAqEAACAQMDBAEDBQEAAAAAAAABAgMABBEFEiEGE0FRIhQjMTJCYXGBkf/EABgBAQEBAQEAAAAAAAAAAAAAAAUEAwEG/8QAJBEAAgEDBQACAwEAAAAAAAAAAQIRAAMhBBIxQVEFMhQjYXH/2gAMAwEAAhEDEQA/AM+0fQDfajFbXJkt0cFt7Rn9PumxOnI4NQ0+yecMpdu5IMLiNW8Gtm1MBDBbSW6MrbexIoUNgeMcDGazPUrG7udVffGIiBg4OEX3j1TPx2ktiW7IMGeqG1LkGOYIkUrXOktbNtI53uPYwDxX2KwZhnbx7PApxTpy/wCxNPHD3I403Fz69qD+aorASATk0/aKsCA6sRzULyIJUgHihUWnxkgZz/XA/wCmmnR7W3t2YrkSNtXuqWOwE5PA/OaB317psVssLSPHMJd0kyfLagHAx5NdTdU6ei40yymzuzvmfgcYOAPdG39fpy9y0WODE9Eikbfx2q2W7gT7LujwGjvUXStprFvPdWqvFetOHHDBJV9nP7qx+bQtRiuHtZIvux8nJ+OP4NbZZa1dy2D6lqV7FEsjIkXHAUeFXyzeKSOo7qzjvpps/elRWcL+QxGcV5K6wuay4iJCjzOasZdoAJlq2C+utJNsqXjDuAfAK+91zVnSLZXsXM1rvMw5cDkKRSBPN1Pa2sL3hs7tV2h2kwO2fCueCQPdcw9Xa3EHQpCi+o+QceifdK/lWTZ2JdyWnLcf5U+x1u73tGAsfWn+1aSO3x9cysm5Oy6B8ehz4rNepbSRLSaVJIzljmGIkEec+9prw1PXr+9gb6pGQKSe4h2hgRhUO00Dv1vbaAzXkzSGWPO70Rt4qK9qL9x7qrAQ+YPPoq3QhRqLOJiZ3cDHhpelm0NIswyXMknn4BEH9csTXsLiO5hmiXTxb7YC2/DBnZfe8k0Vv/pprX6gQ5VZAZGRTFEiE4X9PmhFhdwCcHdE7mM8nc3AGcHNZH646pm1cLPbLGN3XHNMsOrS3enxLdxKJkYywnh1dsbeR44pbtFm1PUlM8awvKCqhRhR8Tgf7RH6qK9gja2hhtWkHyAOFLHj4CiKdJarbxQ373G142DuDyxQYIJArgVU3mNm6YkyZoMj9hlSOCRViyvrhWkBYussBV0cllYMcnIqvDo9pNAkrGQMfTVKlRozZyaQCKSkqDg1esy1vFexxOygFFPOd4Y/uB4OKC9V30g0a2kREUveSLgA491KlNuI0unPZST/AGsRi7dHhxSSbiSdJJHAB2/hRgVdslxe24DNhmQH+dxxUqVFJ96roAkY7FaRHpNnoyrPAhkkiYOhmw2D/mKBTavf3ExllmJaWUq3gHIqVKltEs7SZyaz1eHx4K//2Q");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='$'%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeColorMatrix type='matrix' values='1 0 0 0 0,0 1 0 0 0,0 0 1 0 0,0 0 0 9 0'/%3E%3CfeComposite in2='SourceGraphic' operator='in'/%3E%3C/filter%3E%3C/svg%3E#$");
      transition: all 1s;
      opacity: 1;
      z-index: 1;
    }
  }
  .bg-img-loaded {
    &::before {
      opacity: 0;
    }
  }
  
  .contentdiv {
    z-index: 3;
    margin: 0 auto;
    width: 600px;
    height: 400px;
    box-shadow: 0 0 20px rgba(0,0,0, 0.4);
    background-color: rgba(255,255,255, 0.6);
    border-radius: 4px;
    position: relative;
    display: flex; /*开启弹性布局*/
    /*设定主轴方向，默认主轴方向是水平方向row,垂直方向是column*/
    flex-direction: row;
    justify-content: center; /*主轴居中*/
    align-items: center; /*副轴居中*/
    .my_img {
      position: absolute;
      left: 240px;
      top: -60px;
      border-radius: 50%;
      box-shadow: 0 0 10px #888888;
      transition: all 1s;
    }
    .my_img:hover {
      transform: rotate(360deg);
    }
    .formdiv {
      /* border: 1px solid red; */
      // margin: 0 auto;
      width: 420px;
      height: 280px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      // margin-top: 100px;

      .btncol {
        // margin-left: 20px;
        position: absolute;
        left: 100px;
        display: flex;
      }
    }
  }
}
.footer {
  z-index: 3;
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
  padding-bottom: 20px;
  a {
    color: rgb(60, 128, 105);
  }
}
</style>
