export const validatePass = {
    chkPwd(rule, value, callBack) {
        let pwdExp = /^[0-9a-zA-Z]{6,12}$/;
        if (!pwdExp.test(value)) {
            return callBack(new Error('密码只能是6-12位数字大小写字母'));
        }
        callBack();
    }
}

export const basicMesssageRules = {
    chkPrice(rule, value, callBack) {
        if (isNaN(value) || value <= 0) {
            return callBack(new Error('请输入正确的价格'));
        }
        callBack();
    },
    chkWeight(rule, value, callBack) {
        if (isNaN(value) || value <= 0) {
            return callBack(new Error('请输入正确的重量'));
        }
        callBack();
    },
    chkNumber(rule, value, callBack) {
        if (isNaN(value) || value <= 0) {
            return callBack(new Error('请输入正确的数量'));
        }
        callBack();
    }
}

