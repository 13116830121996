<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Logout',
  data () {
    return {
      
    };
  },
  components: {},
  mounted () {
      sessionStorage.removeItem('jwttoken');
      this.$message.success('登出成功');
      this.$router.replace('/login')
  },
  methods: {}
}
</script>

<style lang='less' scoped>
</style>